/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// Scroll then fixed nav
				var navHeight = $('.navbar').outerHeight();
				$(window).bind('scroll', function () {
					if ($(window).scrollTop() > 200) {
						$('header.banner').css('min-height', navHeight);
						$('.navbar').addClass('fixed').delay(100).queue(function () {
							$(this).addClass("top").dequeue();
						});
					} else {
						$('.navbar').removeClass('fixed top');
						$('header.banner').css('min-height', 0);
					}
				});
				// Masonry layout
				$('.grid').masonry({
					// options
					itemSelector: '.grid-item',
					columnWidth: '.grid-sizer',
					percentPosition: false,
					gutter: '.gutter-sizer'
				});
				// Project Modal
				$('.grid-item').on('click', function () {
					if (!$('.project-modal-open').length) {
						$('body').addClass('project-modal-open');
						$(this).find('.project-modal').addClass('open');
						$('.project-modal').fadeIn();
					}
				});
				$('.project-modal-close').click(function (event) {
					event.stopPropagation();
					closeModal();
				});
				$(document).ready(function(){
					$(document).bind('keydown', function(e) {
						if (e.which == 27) {
							closeModal();
						}
					});
				});
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
				$('.project-modal').hide();
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

/**
 * Close modal
 */
function closeModal() {
	jQuery('.project-modal.open').fadeOut().removeClass('open');
	jQuery('body').removeClass('project-modal-open');
}

/**
 * Google Map
 */
if (jQuery('#contact-map').length) {
	google.maps.event.addDomListener(window, 'load', init);
	var map;

	function init() {
		var mapOptions = {
			center: new google.maps.LatLng(49.711306, -112.781889),
			zoom: 15,
			zoomControl: true,
			zoomControlOptions: {
				style: google.maps.ZoomControlStyle.DEFAULT,
				position: google.maps.ControlPosition.TOP_LEFT
			},
			disableDoubleClickZoom: true,
			mapTypeControl: false,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
			},
			scaleControl: true,
			scrollwheel: true,
			panControl: true,
			streetViewControl: true,
			draggable: true,
			overviewMapControl: true,
			overviewMapControlOptions: {
				opened: false,
			},
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: [{
				"featureType": "all",
				"elementType": "all",
				"stylers": [{"invert_lightness": true}, {"saturation": 10}, {"lightness": 30}, {"gamma": 0.5}, {"hue": "#435158"}]
			}]
		};
		var mapElement = document.getElementById('contact-map');
		var map = new google.maps.Map(mapElement, mapOptions);
		var locations = [
			['BCB Engineering', 'undefined', '403-320-4888', 'undefined', 'http://bcbengineering.com', 49.711306, -112.781889, '/wp-content/themes/bcb-engineering/dist/images/map-marker.png']
		];
		for (i = 0; i < locations.length; i++) {
			if (locations[i][1] === 'undefined') {
				description = '';
			} else {
				description = locations[i][1];
			}
			if (locations[i][2] === 'undefined') {
				telephone = '';
			} else {
				telephone = locations[i][2];
			}
			if (locations[i][3] === 'undefined') {
				email = '';
			} else {
				email = locations[i][3];
			}
			if (locations[i][4] === 'undefined') {
				web = '';
			} else {
				web = locations[i][4];
			}
			if (locations[i][7] === 'undefined') {
				markericon = '';
			} else {
				markericon = locations[i][7];
			}
			marker = new google.maps.Marker({
				icon: markericon,
				position: new google.maps.LatLng(locations[i][5], locations[i][6]),
				map: map,
				title: locations[i][0],
				desc: description,
				tel: telephone,
				email: email,
				web: web
			});
			link = '';
		}
		var contentString = '<span style="color: #333; line-height: 1.5"><h4 style="color: inherit;">BCB Engineering</h4><p>#3 4010 9 Ave N<br>Lethbridge, AB T1H 6T8</p></span>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});
		marker.addListener('click', function () {
			infowindow.open(map, marker);
		});
	}
}

